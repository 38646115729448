function joazin(id, opcoes){
    if($(id).children().length > 1){
        $(id).owlCarousel(opcoes)
    }else{
        $(id).addClass('owl-loaded');
    }
}

joazin('#destaques-carousel',{
    loop:true,
    autoplay:true,
    autoplayTimeout:4000,
    nav:false,
    responsive:{
        0:{
            items:1,
        },
        600:{
            items:2,
            margin:4,
        },
        920:{
            items:3,
            margin:8,
        },
        1170:{
            items:4,
            margin:10,
        }
    }
})

joazin('#noticias-carousel',{
    loop:true,
    autoplay:true,
    autoplayTimeout:4000,
    nav:false,
    responsive:{
        0:{
            items:1,
        },
        600:{
            items:2,
            margin:4,
        },
        920:{
            items:3,
            margin:8,
        },
        1170:{
            items:3,
            margin:10,
        }
    }
})

joazin('#parceiros-carousel',{
    loop:true,
    autoplay:true,
    autoplayTimeout:4000,
    nav:false,
    dotsEach:2,
    responsive:{
        0:{
            items:1,
        },
        600:{
            items:3,
            margin:4,
        },
        920:{
            items:4,
            margin:7,
        },
        1170:{
            items:6,
            margin:10,
        }
    }
})

joazin('#bene-owl',{
    loop:false,
    autoplay:false,
    autoplayTimeout:4000,
    nav:true,
    navText: [`<i class="icon prev" aria-label="Prev"></i><span class="sr-only">Prev</span>`,
	`<i class="icon next" aria-label="Next"></i><span class="sr-only">Next</span>`],
    dots: false,
    mouseDrag: false,
    responsive:{
        0:{
            items:3,
            margin: 10,
        },
        600:{
            items:4,
            margin:10,
        },
        920:{
            items:4,
            margin:10,
        },
        1170:{
            items:4,
            margin:10,
        }
    }
})

joazin('#dep-owl',{
    loop:true,
    autoplay:false,
    nav:true,
    navText: [`<i class="icon prev" aria-label="Prev"></i><span class="sr-only">Prev</span>`,
	`<i class="icon next" aria-label="Next"></i><span class="sr-only">Next</span>`],
    dots: false,
    mouseDrag: false,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:2,
            margin:10,
        },
        920:{
            items:2,
            margin:10,
        },
        1170:{
            items:3,
            margin:10,
        }
    }
})


var $carouselEspecial = $('#bene-owl');
$carouselEspecial.find('.nav-link').on('click', function(e) {

    // Remove active dos links e ativa o clicado
    $carouselEspecial.find('.nav-link').removeClass('active');
    $(this).addClass('active');
});

$(document).ready(function(){
    // Evento de clique nos nav-links
    $('.nav-link-especial').on('shown.bs.tab', function () {
        // Fecha todas as descrições
        $('.oferec__desc').collapse('hide');
        
        // Abre a descrição dentro do link ativo
        $(this).find('.oferec__desc').collapse('show');
    });
});
