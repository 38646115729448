(function() {
	const observer = new IntersectionObserver(entries => {
		for (const entry of entries) {
			if(entry.isIntersecting) {
				let bg = entry.target.dataset.lazyBg || '';

				bg = JSON.stringify(bg);
				entry.target.style.setProperty('background-image', `url(${bg})`);
				entry.target.removeAttribute('data-lazy-bg');

				observer.unobserve(entry.target);
			}
		}
	}, {
		rootMargin: '200px 200px 200px 200px'
	});

	const LazyBG = document.querySelectorAll('[data-lazy-bg]');

	for(const el of LazyBG) {
		observer.observe(el);
	}
})();
