(function() {
	const observer = new IntersectionObserver(entries=> {
		for(const entry of entries) {
			if(entry.isIntersecting) {
				entry.target.lazyimage.render();
				observer.unobserve(entry.target);
			}
		}
	}, {
		rootMargin: '200px 200px 200px 200px'
	});
	const lazyimage = document.querySelectorAll('lazyimage, lazy-image');

	for(let img of lazyimage) {
		new LazyImage(img);
	}

	function LazyImage(el) {
		this.src       = el.getAttribute('src') || el.getAttribute('data-src');
		this.alt       = el.getAttribute('alt') || el.getAttribute('data-alt');
		this.classList = ['img-fluid', 'img-responsive'];

		const self = this;

		if(el.hasAttribute('data-classe')) {
			let classes = el.dataset.classe.split(' ');

			for(let classe of classes) {
				this.classList.push(classe);
			}
		}

		this.render = function() {
			let img = document.createElement('img');

			img.src = self.src;
			img.alt = self.alt;

			for(let classe of self.classList) {
				img.classList.add(classe);
			}

			el.appendChild(img);

			img.addEventListener('load', ()=> {
				el.setAttribute('loaded','true');
				img.width  = img.naturalWidth;
				img.height = img.naturalHeight;
			});
		}

		el.lazyimage = this;

		observer.observe(el);
	}
})();
