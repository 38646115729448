(function() {
	const observer = new IntersectionObserver(entries => {
		for(const entry of entries) {
			if(entry.isIntersecting) {
				entry.target.className = entry.target.className+' '+entry.target.dataset.animate;
				entry.target.removeAttribute('data-animate');
				observer.unobserve(entry.target);
			}
		}
	}, {
		rootMargin: '-100px -100px -100px -100px'
	});
	const els = document.querySelectorAll('[data-animate]');

	for(const el of els) {
		observer.observe(el);
	}
})();
